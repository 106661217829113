import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'

import { MouseParallaxContainer, MouseParallaxChild } from 'react-parallax-mouse'

import {
  Content,
  PersonContainer,
  PartnerSlider,
  ArrowLink,
  FloatingCircle,
  childStyles,
  TeamSlider
} from '../Atoms'
import { Bonuses, ServicesPanel, ValueStack, FourSteps, MakeMore } from '../Sections'
import Layout from '../Layout'
import { taxTeam } from './AboutUs'

import { labels, localeUrls } from '../../langs/langs'

import check from '../../images/circle-check-green.svg'
import checkBlue from '../../images/circle-check-blue.svg'
import spider from '../../images/spider.svg'

import notallowed from '../../images/notallowed.svg'
import clock from '../../images/clock.svg'
import yoke from '../../images/yoke.svg'
import pig from '../../images/pig.svg'

import personG from '../../images/grad-person.svg'
import shieldG from '../../images/grad-shield.svg'
import spiderG from '../../images/grad-spider.svg'

import gPerson from '../../images/person-green.svg'
import gClock from '../../images/clock-green.svg'
import gLock from '../../images/lock-green.svg'
import gGlasses from '../../images/glasses-green.svg'
import gCase from '../../images/case-green.svg'
import gSquares from '../../images/squares-green.svg'
import gCity from '../../images/city-green.svg'

import i30 from '../../images/30.svg'
import yokeB from '../../images/yoke-blue.svg'
import glassesB from '../../images/glasses-blue.svg'

import par1 from '../../images/main-partners/audit.png'
import par2 from '../../images/main-partners/group.png'
import par3 from '../../images/main-partners/ruzicka.png'
import par4 from '../../images/main-partners/skdp.png'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const ServiceCard = ({ children, icon, heading, urlLabel, bgImg, to }) => {
  return (
    <AnchorLink
      to={to}
      className="group px-10 sm:px-14 py-10 xl:py-16 rounded-2xl overflow-hidden text-white relative text-left grid grid-rows-[auto_auto_1fr_auto] isolate"
    >
      {bgImg}
      <div className="bg-bg-dark/60 absolute inset-0 -z-10" />
      <Fade>
        <img src={icon} className="block" />
      </Fade>
      <h3 className="font-bold text-2xl pt-3 pb-6">{heading}</h3>
      <div>{children}</div>
      <ArrowLink span={true} className="text-green pt-3">
        {urlLabel}
      </ArrowLink>
    </AnchorLink>
  )
}
const ServiceCardChild = ({ children, icon }) => {
  return (
    <div className="flex items-start gap-2 pb-3 text-sm translate-z=">
      <img src={icon} />
      <p>{children}</p>
    </div>
  )
}
const serviceCardImgClasses =
  '!absolute inset-0 -z-10 group-hover:blur duration-500 group-hover:scale-105 -z-10'
const sCardBg1 = (
  <StaticImage src="../../images/scard1.jpg" className={serviceCardImgClasses} alt="background" />
)
const sCardBg2 = (
  <StaticImage src="../../images/scard2.jpg" className={serviceCardImgClasses} alt="background" />
)
const sCardBg3 = (
  <StaticImage src="../../images/scard3.jpg" className={serviceCardImgClasses} alt="background" />
)

const Home = ({ localeID, location }) => {
  return (
    <Layout location={location} title={labels[localeID]['WELCOME']} localeID={localeID}>
      <MouseParallaxContainer className="lg:h-[calc(100vh_-_3.75rem)] max-h-[52rem] min-h-max bg-dark py-16 relative">
        <MouseParallaxChild updateStyles={childStyles} factorX={-0.015} factorY={0.015}>
          <FloatingCircle className="bg-green -bottom-[15vh] -right-[8vw] md:right-[4vw]" />
        </MouseParallaxChild>
        <MouseParallaxChild updateStyles={childStyles} factorX={0.005} factorY={-0.02}>
          <FloatingCircle className="bg-blue -top-[8vw] lg:top-[9vh] lg:right-[20vw] 2xl:right-[26vw]" />
        </MouseParallaxChild>
        <MouseParallaxChild updateStyles={childStyles} factorX={0.01} factorY={0.005}>
          <FloatingCircle className="bg-bggradient -right-[22vw] top-[8vw] md:-right-[4vw] lg:-top-[20vh] lg:-right-[12vw]" />
        </MouseParallaxChild>
        <PersonContainer />
        <Content className="text-white lg:hcenter h-full">
          <div>
            <h1 className="text-3xl sm:text-5xl font-light -tracking-[0.5px]">
              {parse(labels[localeID]['HERO_TITLE'])}
            </h1>
            <div className="text-lg pt-5 pb-10">
              <Fade cascade={true} damping={0.1} triggerOnce={false}>
                <p className="hcenter py-1">
                  <img src={check} alt="check icon" />
                  <span>{parse(labels[localeID]['HERO_P_1'])}</span>
                </p>
                <p className="hcenter py-1">
                  <img src={check} alt="check icon" />
                  <span>{parse(labels[localeID]['HERO_P_2'])}</span>
                </p>
                <p className="hcenter py-1">
                  <img src={check} alt="check icon" />
                  <span>{parse(labels[localeID]['HERO_P_3'])}</span>
                </p>
              </Fade>
            </div>
            <Link to={localeUrls[localeID]['CTA']} className="button2">
              {labels[localeID]['WORK_W_US']}
            </Link>
          </div>
        </Content>
      </MouseParallaxContainer>
      <ValueStack localeID={localeID} />
      <div className="py-24 sm:py-32">
        <Content className="text-center">
          <h2 className="text-3xl font-bold -tracking-[0.5px]">
            {parse(labels[localeID]['NOT_BURDEN'])}
          </h2>
          <p className="pt-12 pb-4">{parse(labels[localeID]['PROBLEM'])}</p>
          <div className="hcenter justify-center flex-wrap gap-4">
            <Fade cascade={true} damping={0.1} triggerOnce={false}>
              <div className="buttonD">
                <img src={notallowed} />
                <p>{labels[localeID]['PROBLEM_1']}</p>
              </div>
              <div className="buttonD">
                <img src={clock} />
                <p>{labels[localeID]['PROBLEM_2']}</p>
              </div>
              <div className="buttonD">
                <img src={yoke} />
                <p>{labels[localeID]['PROBLEM_3']}</p>
              </div>
              <div className="buttonD">
                <img src={pig} />
                <p>{labels[localeID]['PROBLEM_4']}</p>
              </div>
            </Fade>
          </div>
          <p className="hcenter pt-12 pb-4 justify-center">
            <img src={checkBlue} />
          </p>
          <p className="pb-12 max-w-2xl relative mx-auto">{parse(labels[localeID]['SOLUTION'])}</p>
          <Fade>
            <Link to={localeUrls[localeID]['CTA']} className="button2">
              {labels[localeID]['WORK_W_US']}
            </Link>
          </Fade>
        </Content>
      </div>
      <div className="pb-24 sm:pb-32">
        <Content className="text-center">
          <h2 className="text-6xl font-bold -tracking-[1px]">{labels[localeID]['KEY_PILLARS']}</h2>
          <p className="gradtext text-2xl pt-3">{labels[localeID]['KEY_PILLARS_C']}</p>
          <div className="py-20 grid grid-cols-1 gap-4 xl:gap-6 xl:grid-cols-3">
            <ServiceCard
              heading={labels[localeID]['KEY_PILLARS_1']}
              icon={personG}
              bgImg={sCardBg1}
              urlLabel={labels[localeID]['SCARD_LABEL_1']}
              to={localeUrls[localeID]['ABOUT_US']}
            >
              <ServiceCardChild icon={gPerson}>
                {parse(labels[localeID]['SCARD_P_1'])}
              </ServiceCardChild>
              <ServiceCardChild icon={gClock}>
                {parse(labels[localeID]['SCARD_P_2'])}
              </ServiceCardChild>
            </ServiceCard>
            <ServiceCard
              heading={labels[localeID]['KEY_PILLARS_2']}
              icon={shieldG}
              bgImg={sCardBg2}
              urlLabel={labels[localeID]['SCARD_LABEL_2']}
              to={'#about-us'}
            >
              <ServiceCardChild icon={gLock}>
                {parse(labels[localeID]['SCARD_P_3'])}
              </ServiceCardChild>
              <ServiceCardChild icon={gGlasses}>
                {parse(labels[localeID]['SCARD_P_4'])}
              </ServiceCardChild>
              <ServiceCardChild icon={gCase}>
                {parse(labels[localeID]['SCARD_P_5'])}
              </ServiceCardChild>
            </ServiceCard>
            <ServiceCard
              heading={labels[localeID]['KEY_PILLARS_3']}
              icon={spiderG}
              bgImg={sCardBg3}
              urlLabel={labels[localeID]['SCARD_LABEL_3']}
              to={localeUrls[localeID]['SERVICES']}
            >
              <ServiceCardChild icon={gSquares}>
                {parse(labels[localeID]['SCARD_P_6'])}
              </ServiceCardChild>
              <ServiceCardChild icon={gCity}>
                {parse(labels[localeID]['SCARD_P_7'])}
              </ServiceCardChild>
            </ServiceCard>
          </div>
          <Fade>
            <Link to={localeUrls[localeID]['CTA']} className="button2">
              {labels[localeID]['WORK_W_US']}
            </Link>
          </Fade>
        </Content>
      </div>
      <PartnerSlider />
      <div className="py-24 sm:py-32" id="about-us">
        <Content className="grid grid-cols-1 gap-20 lg:grid-cols-2 xl:gap-0">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 order-2 lg:order-1">
            <div>
              <img src={yokeB} />
              <p className="font-bold py-3">{labels[localeID]['EXP_P_H_1']}</p>
              <p className="text-sm">{parse(labels[localeID]['EXP_P_1'])}</p>
            </div>
            <div>
              <img src={spider} />
              <p className="font-bold py-3">{labels[localeID]['EXP_P_H_2']}</p>
              <p className="text-sm">{parse(labels[localeID]['EXP_P_2'])}</p>
            </div>
            <div>
              <img src={glassesB} />
              <p className="font-bold py-3">{labels[localeID]['EXP_P_H_3']}</p>
              <p className="text-sm">{parse(labels[localeID]['EXP_P_3'])}</p>
            </div>
            <div>
              <img src={i30} />
              <p className="font-bold py-3">{labels[localeID]['EXP_P_H_4']}</p>
              <p className="text-sm">{parse(labels[localeID]['EXP_P_4'])}</p>
            </div>
          </div>
          <div className="xl:pl-20  lg:order-2">
            <p className="font-bold text-3xl leading-10 -tracking-[0.5px]">
              {parse(labels[localeID]['LONG_EXP'])}
              <br />
              <span className="gradtext">{labels[localeID]['GUARAN']}</span>
            </p>
            <div className="hcenter flex-wrap gap-10 py-12">
              <img src={par1} />
              <img src={par2} />
              <img src={par3} />
              <img src={par4} />
            </div>
            <Link to={localeUrls[localeID]['CTA']} className="button2">
              {labels[localeID]['WORK_W_US']}
            </Link>
          </div>
        </Content>
      </div>
      <div className="pb-14 sm:pt-10">
        <Content className="hcenter">
          <h2 className="text-3xl sm:text-[2.625rem] font-bold">
            {labels[localeID]['KEY_PILLARS_3']}
          </h2>
          <div className="flex-auto" />
          <ArrowLink to={localeUrls[localeID]['SERVICES']} className="text-sm sm:text-lg text-blue">
            {labels[localeID]['ALL_SERVICES']}
          </ArrowLink>
        </Content>
      </div>
      <ServicesPanel localeID={localeID} />
      <Bonuses localeID={localeID} />
      <div className="bg-dark text-white pt-32 text pt pt">
        <Content>
          <h2 className="decor-green text-4xl -tracking-[0.5px] font-bold">
            {parse(labels[localeID]['FIND_PARTNER'])}
          </h2>
        </Content>
      </div>
      <TeamSlider
        title={
          <p className="max-w-xs text-white hidden sm:block">
            {parse(labels[localeID]['FIND_PARTNER_C'])}
          </p>
        }
        bg={true}
        localeID={localeID}
        showAllUrl={localeUrls[localeID]['ABOUT_US']}
        arrowClasses="text-green"
      >
        {taxTeam(localeID)}
      </TeamSlider>
      <FourSteps localeID={localeID} />
      <MakeMore localeID={localeID} />
    </Layout>
  )
}

export default Home
